import styled, { keyframes } from 'styled-components'
import { Flex, Box, Heading, Text, Link, useMatchBreakpointsContext } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'

const Wrapper = styled(Flex)`
  z-index: 1;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media all and (max-width: 479px) {
    padding: 3px 24px;
  }
  @media all and (max-width: 369px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`

const hoverAnim = () => keyframes`
    0% {
        opacity: 0.4;
    }
    2% {
        opacity: 0.5;
    }
    4% {
        opacity: 0.6;
    }
    6% {
        opacity: 0.85;
    }
    12% {
        opacity: 0.5;
    }
    18% {
        opacity: 1;
    }
    20% {
        opacity: 0.1;
    }
    22% {
        opacity: 0.25;
    }
    24% {
        opacity: 0.5;
    }
    26% {
        opacity: 1;
    }
    40% {
        opacity: 0.85;
    }
    42% {
        opacity: 0.4;
    }
    44% {
        opacity: 0.5;
    }
    48% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`

const CategoryBlock = styled(Flex)`
  display: block;
  width: 23%;
  max-width: 380px;
  height: 185px;
  padding: 2px;
  border-radius: 12px;
  margin: 27px 0;
  transition: scale 200ms;
  &:nth-child(1) {
    background: linear-gradient(-270deg, rgb(247, 20, 255), rgb(192, 92, 247));
    &:hover{
      scale: 1.04;
    }
  }
  &:nth-child(2) {
    background: linear-gradient(-270deg, rgb(178, 104, 247), rgb(135, 140, 249));
    filter: saturate(0);
    &:hover{
      // scale: 1.04;
    }
  }
  &:nth-child(4) {
    background: linear-gradient(-270deg, rgb(125, 148, 250), rgb(71, 195, 252));
    filter: saturate(0);
    &:hover{
      // scale: 1.04;
    }
  }
  &:nth-child(5) {
    background: linear-gradient(-270deg, rgb(8, 200, 255), rgb(60, 206, 255));
    filter: saturate(0);
    &:hover{
      // scale: 1.04;
    }
  }
  @media all and (max-width: 1299px) {
    height: 130px;
    margin: 27px 0;
  }
  @media all and (max-width: 949px) {
    height: 106px;
    margin: 39px 0;
  }
  @media all and (max-width: 849px) {
    height: 106px;
    margin: 39px 0;
    width: 45%;
  }
  @media all and (max-width: 479px) {
    height: 83px;
    width: 49%;
    margin: 3px 0;
  }
`
const CategoryBlockInner = styled(Link)`
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  background: #1b1b1b;
  border-radius: 10px;
  background-size: 100% auto;
  color: white;
`
const CategoryText = styled(Text)`
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-size: 26px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -1px; 
  ${({ theme }) =>
    theme.isDark
      ? ' text-shadow: 0 1px 30px rgb(216,0,255), 0 2px 8px rgb(208,0,255,0.4); color: white; '
      : '     background: linear-gradient(270deg,rgb(206 28 255) 0%,rgba(240,50,245,1) 100%); color: #d800ff; '}
  ${({ theme }) =>
    theme.isDark
      ? ''
      : ' -webkit-background-clip: text; -webkit-text-fill-color: transparent; animation: none !important; '}
    @media all and (max-width: 949px) {
    bottom: 10px;
  }
  @media all and (max-width: 479px) {
    bottom: 6px;
    font-size: 22px;
    left: 15px;
  }
`

const CategoryTextDisabled = styled(Text)`
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-size: 26px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -1px;
  text-shadow: ${({ theme }) => (theme.isDark ? '0 2px 50px rgb(215 213 215), 0 1px 10px rgb(0 0 0)' : 'none')};
  color: ${({ theme }) => (theme.isDark ? '#525252' : theme.colors.textDisabled )};
  @media all and (max-width: 949px) {
    bottom: 10px;
  }
  @media all and (max-width: 479px) {
    bottom: 6px;
    font-size: 22px;
    left: 15px;
  }
`

const CategoryBlockActive = styled(Flex)`
  display: block;
  width: 22.6%;
  height: 130px;
  padding: 2px;
  border-radius: 12px;
  margin: 27px 1.2%;
  &:nth-child(1) {
    background: linear-gradient(-270deg, rgb(247, 20, 255), rgb(192, 92, 247));
  }
  transition: transform 200ms;
  &:hover {
    transform: scale(1.04);
  }
  &:hover ${CategoryText} {
    animation: ${hoverAnim} 3s linear infinite;
  }
  @media all and (max-width: 949px) {
    height: 106px;
    margin: 39px 1.2%;
  }
  @media all and (max-width: 849px) {
    height: 106px;
    margin: 39px 1.2%;
    width: 45.2%;
  }
  @media all and (max-width: 479px) {
    height: 83px;
    width: 49%;
    margin: 3px 0;
  }
`

const CategoryBreak = styled.div`
  display: none;
  @media all and (max-width: 849px) {
    display: block;
    flex-basis: 100%;
    height: 0;
  }
`

const CategoryBanner = () => {
  const { theme } = useTheme()
  const category1 = theme.isDark
    ? 'url("/images/home/categories/swap-icon.jpg")'
    : 'url("/images/home/categories/swap-icon-light.jpg")'
  const category2 = theme.isDark
    ? 'url("/images/home/categories/farm-icon.jpg")'
    : 'url("/images/home/categories/farm-icon-light.jpg")'
  const category3 = theme.isDark
    ? 'url("/images/home/categories/nft-icon.jpg")'
    : 'url("/images/home/categories/nft-icon-light.jpg")'
  const category4 = theme.isDark 
    ? 'url("/images/home/categories/win-icon.jpg")' 
    : 'url("/images/home/categories/win-icon-light.jpg")'

  return (
    <>
      <Wrapper
        position="relative"
        flexDirection={['row', 'row', 'row', 'row']}
        alignItems={['center', 'center', 'center', 'center']}
        justifyContent="space-between"
        padding="0"
        maxWidth="1680px"
      >
        <CategoryBlock>
          <CategoryBlockInner href="/swap" style={{ backgroundImage: category1 }}>
            <CategoryText>Swap</CategoryText>
          </CategoryBlockInner>
        </CategoryBlock>
        <CategoryBlock>
          <CategoryBlockInner href="/" style={{ backgroundImage: category2, cursor: 'initial' }}>
            <CategoryText>Farm</CategoryText>
          </CategoryBlockInner>
        </CategoryBlock>
        <CategoryBreak />
        <CategoryBlock>
          <CategoryBlockInner href="/" style={{ backgroundImage: category4, cursor: 'initial' }}>
            <CategoryText>Win</CategoryText>
          </CategoryBlockInner>
        </CategoryBlock>
        <CategoryBlock>
          <CategoryBlockInner href="/" style={{ backgroundImage: category3, cursor: 'initial' }}>
            <CategoryText>NFTS</CategoryText>
          </CategoryBlockInner>
        </CategoryBlock>
      </Wrapper>
    </>
  )
}

export default CategoryBanner;
