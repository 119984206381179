import styled from 'styled-components'
import { Flex, Text, Button, Link, Heading, Box } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import CompositeImage, { CompositeImageProps } from '../CompositeImage'
import CompositeImageZAP from '../CompositeImageZAP'
import ColoredWordHeading from '../ColoredWordHeading'

interface SalesSectionButton {
  to: string
  text: string
  external: boolean
}

export interface SalesSectionProps {
  headingText: string
  bodyText: string | string[]
  reverse: boolean
  primaryButton: SalesSectionButton
  secondaryButton: SalesSectionButton
  images: CompositeImageProps
}

const SalesSectionDiv = styled.div`
  
  display: block;
  z-index: 2;
  padding-top: 40px;
`

const SecondHeading = styled(Heading)`
  text-transform: uppercase;
  letter-spacing: -2px;
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 15px;
  background: linear-gradient(270deg,rgba(8,200,255,1) 0%,rgba(240,50,245,1) 100%);
  // background: linear-gradient(270deg, rgba(240, 50, 245, 1) 0%, rgb(215 47 48) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`
const ContentBox = styled(Box)`
  max-width: 520px;
  @media all and (min-width: 1200px){
    margin-left: 100px;
  }
`
const SalesSection: React.FC<SalesSectionProps> = (props) => {
  const { headingText, bodyText, reverse, primaryButton, secondaryButton, images } = props

  return (
    <SalesSectionDiv>
      <Flex flexDirection="column">
        <Flex
          flexDirection={['column-reverse', null, null, reverse ? 'row-reverse' : 'row']}
          alignItems={['flex-end', null, null, 'center']}
          justifyContent="center"
        >
          <Flex
            flexDirection="column"
            flex="1"
            ml={[null, null, null, reverse && '64px']}
            mr={[null, null, null, !reverse && '64px']}
            alignSelf={['flex-start', null, null, 'center']}
            style={{ zIndex: '2' }}
          >
            <ContentBox>
              <SecondHeading>{headingText}</SecondHeading>
              <Text color="text" mb="24px">
                {bodyText}
              </Text>
              <Flex>
                {primaryButton.external ? (
                  <Link external href={primaryButton.to}>
                    <Button mr="8px">
                      <Text color="card" bold>
                        {primaryButton.text}
                      </Text>
                    </Button>
                  </Link>
                ) : (
                  <RouterLink to={primaryButton.to}>
                    <Button mr="8px" minWidth="100px">
                      <Text color="card" bold>
                        {primaryButton.text}
                      </Text>
                    </Button>
                  </RouterLink>
                )}
                {secondaryButton.external ? (
                  <Link external href={secondaryButton.to}>
                    <Button variant="secondary" minWidth="100px">{secondaryButton.text}</Button>
                  </Link>
                ) : (
                  <RouterLink to={secondaryButton.to}>
                    <Button variant="secondary" minWidth="100px">{secondaryButton.text}</Button>
                  </RouterLink>
                )}
              
              </Flex>
            </ContentBox>
          </Flex>
          <Flex
            height={['192px', null, null, '100%']}
            width={['192px', null, null, '100%']}
            flex={[null, null, null, '1']}
            mb={['24px', null, null, '0']}
          >
            {headingText === 'ZSwap is powered by the ZAP token.' ? (
              <CompositeImageZAP {...images} />
            ) : (
              <CompositeImage {...images} />
            )}
          </Flex>
        </Flex>
      </Flex>
    </SalesSectionDiv>
  )
}

export default SalesSection
