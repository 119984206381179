import { TranslateFunction } from 'contexts/Localization/types'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Swap'),
  bodyText: t('ZSwap offers the fastest, most responsive and decentralized trading experience on Avalanche. Get started in seconds, just by connecting your wallet.'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Swap'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.zswap.plus/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: 'AVAX', alt: t('AVAX token') },
      { src: 'WBTC', alt: t('WBTC token') },
      { src: 'SUB', alt: t('SUB token') },
    ],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Earn passive income with crypto.'),
  bodyText: t('ZSwap makes it easy to make your crypto work for you.'),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.zswap.plus/yield-farming',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/earn/',
    attributes: [
      { src: 'pie', alt: t('Pie chart') },
      { src: 'stonks', alt: t('Stocks chart') },
      { src: 'folder', alt: t('Folder with cake token') },
    ],
  },
})

export const zapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('ZSwap is powered by the ZAP token.'),
  bodyText: t(
    'Releasing soon... Discover our tokenomics and participate in the airdrop! Are you a project owner interested in a native liquidity grant? Fill out our partner form.',
  ) /* ZAP tokens are like the electricity powering the ZSwap decentralized exchange. Buy them, farm them, win them and stake them! */,
  reverse: false,
  primaryButton: {
    to: 'https://docs.zswap.plus/tokenomics/zap',
    text: t('Tokenomics'),
    external: true,
  },
  secondaryButton: {
    to: 'https://docs.zswap.plus/tokenomics/zap',
    text: t('Partner Form'),
    external: true,
  },

  images: {
    path: '/images/home/zap/',
    attributes: [
      { src: 'zap-token-podium', alt: t('Zap UI') },
      { src: 'zap-token', alt: t('ZAP Token') },
    ],
  },
})
