import styled from 'styled-components'
import PageSection from 'components/PageSection'
import { useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from 'contexts/Localization'
import { getImageSize } from 'next/dist/server/image-optimizer'
import { bg } from 'date-fns/locale'
import { AvaxIcon, Button, Modal, Text } from '@pancakeswap/uikit'
import { swapSectionData, earnSectionData, zapSectionData } from './components/SalesSection/data'
import Hero from './components/Hero'
import NewsNotification from './components/NewsNotification'
import MetricsSection from './components/MetricsSection'
import SalesSection from './components/SalesSection'
import TopTokensSection from './components/TopTokensSection'

import TokenTable from '../Info/components/InfoTables/TokensTable'
import { useAllTokenData, useTokenDatas } from '../../state/info/hooks'

// import WinSection from './components/WinSection'
// import FarmsPoolsRow from './components/FarmsPoolsRow'
// import Footer from './components/Footer'
// import CakeDataRow from './components/CakeDataRow'

// import UserBanner from './components/UserBanner'
// import MultipleBanner from './components/Banners/MultipleBanner'

const StyledHeroSection = styled(PageSection)`
  position: relative;
  background-color: #0b0d1c;
  background-size: cover;
  background-position: bottom center;
  overflow: hidden;
  padding-top: 50px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 760px;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 760px;
  }
  ${({ theme }) => theme.mediaQueries.xxxl} {
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 900px;
  }

  @:before{
    display: block;
    content: '';
  }
`

const StyledSecondSection = styled(PageSection)`

  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  background-size: cover;
  background-position: bottom center;
  overflow: hidden;
  padding-top: 100px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 80px;
    min-height: 600px;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    padding-top: 0px;
    min-height: 700px;
  }
`

const HeroCover = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-left: 0px;
  padding-right: 0px;

  ${({ theme }) => (theme.isDark ? 'background: rgba(17,4,34,0.2)' : '')};

  ${({ theme }) => theme.mediaQueries.md} {
    background: transparent none;
  }

  ${({ theme }) => theme.mediaQueries.xxxl} {
    ${({ theme }) => (theme.isDark ? 'background: rgba(17,4,34,0.2)' : '')};
  }
`
const HeroGradient = styled.div`
  position: absolute;
  width: 240%;
  height: 800px;
  bottom: -380px;
  left: -100px;
  rotate: -16deg;
  background: linear-gradient(-50deg, rgba(60, 234, 255, 0.5) 0%, rgba(255, 40, 255, 0.4) 80%);
  opacity: 0.5;
  mix-blend-mode: hard-light;

  ${({ theme }) => theme.mediaQueries.md} {
    position: absolute;
    width: 200%;
    height: 800px;
    bottom: -480px;
    left: -22%;
    rotate: -22deg;
  }
  ${({ theme }) => theme.mediaQueries.xxxl} {
    bottom: -250px;
    left: -8%;
    rotate: -16deg;
  }
`

const SecondGradient = styled.div`
  position: absolute;
  width: 340%;
  height: 800px;
  top: -680px;
  left: -70%;
  rotate: -16deg;
  background: linear-gradient(-50deg, rgba(60, 234, 255, 0.5) 0%, rgba(255, 40, 255, 0.4) 80%);
  opacity: 0.5;
  mix-blend-mode: hard-light;

  ${({ theme }) => theme.mediaQueries.md} {
    position: absolute;
    width: 200%;
    height: 800px;
    top: -480px;
    left: -26%;
    rotate: -22deg;
  }
  ${({ theme }) => theme.mediaQueries.xxxl} {
    top: -740px;
    left: -10%;
    rotate: -16deg;
  }

`

const UserBannerWrapper = styled(Container)`
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 0px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 24px;
    padding-right: 24px;
  }
`
const ThirdGradient = styled.div`
  display: block;
  margin-top: 0;
  height: 200px;
  background: linear-gradient(-270deg, rgb(255 0 255 / 0%) 30%, rgb(255 0 255 / 0%) 70%);
  padding: 20px 0;
  z-index: 2;
  margin-left: -70%;
  margin-right: -70%;
`

const Blackout = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => (theme.isDark ? "#121e2f7d" : "#835f9a63")};
  backdrop-filter: blur(4px);
  z-index: 1000;
`
const StyledModal = styled(Modal)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  overflow: auto;
  margin: auto;
  height: auto;
  border-radius: 12px;
`

const Home: React.FC = () => {
  const { theme } = useTheme()
  const { account } = useWeb3React()

  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }

  const { t } = useTranslation()

  const allTokens = useAllTokenData()

  const formattedTokens = useMemo(() => {
    return Object.values(allTokens)
      .map((token) => token.data)
      .filter((token) => token)
  }, [allTokens])
  

  const [isModalVisible, setIsModalVisible] = useState(false);
  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);


  return (
    <>
      <PageMeta />
      <NewsNotification />
      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        index={2}
        hasCurvedDivider={false}
        background="url('/images/home/hero/bg-dark.jpg')"
      >
        <HeroCover />
        {account && <UserBannerWrapper>{/* <UserBanner /> */}</UserBannerWrapper>}
        <Hero onOpenModal={openModal} />
        <HeroGradient /> {/* Positioned to Bottom */}
      </StyledHeroSection>

      <StyledSecondSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.gradients.bubblegum}
        index={2}
        hasCurvedDivider={false}
        hasCategoryBanner
        style={{ overflow: 'hidden'  }}
      >
        <SecondGradient /> {/* Positioned to Top */}
        <SalesSection {...swapSectionData(t)} />
        {/* ################ ^ This will be changed to zapSection Data: (and the page section below uncommented)
        <SalesSection {...zapSectionData(t)} />
          <CakeDataRow /> 
        */}
      </StyledSecondSection>
      {/*
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.background}
        index={3}
        hasCurvedDivider={false}
      >
        <SalesSection {...swapSectionData(t)} />
      </PageSection>
      */}
      {/* Hide Top Tokens Section:
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.gradients.bubblegum}
        index={4}
        hasCurvedDivider={false}
      >
        <Text color="text" fontSize="22px" mb="30px" fontWeight="800">Top Tokens</Text>
        <TokenTable tokenDatas={formattedTokens} />
      </PageSection>
      */}

      {/* FOR LIVE -- BUILD NEW METRICS SECTION
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        background={
          theme.isDark
            ? 'linear-gradient(180deg, #09070C 22%, #201335 100%)'
            : 'linear-gradient(180deg, #FFFFFF 22%, #D7CAEC 100%)'
        }
        index={2}
        hasCurvedDivider={false}
      >
        <MetricsSection />
      </PageSection>
      */}
      {/* FOR LIVE --- THIS NEEDS CONTENT - FARM PROMO SECTION
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.gradients.cardHeader}
        index={2}
        hasCurvedDivider={false}
      >
        <OuterWedgeWrapper>
          <InnerWedgeWrapper width="150%" top fill={theme.colors.background}>
            <WedgeTopRight />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>
        <SalesSection {...earnSectionData(t)} />
        <FarmsPoolsRow /> 
      </PageSection>
      {/* 
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={
          theme.isDark
            ? 'linear-gradient(180deg, #0B4576 0%, #091115 100%)'
            : 'linear-gradient(180deg, #6FB6F1 0%, #EAF2F6 100%)'
        }
        index={2}
        hasCurvedDivider={false}
      >
        {/* <WinSection /> */}
      {/* </PageSection> */}

      {/* FOR LIVE --- CONSIDER THESE SECTIONS
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background="linear-gradient(180deg, #D800FF 0%, #5121B1 100%)"
        index={2}
        hasCurvedDivider={false}
      >
      {/* <Footer />
      </PageSection>  */}
      {isModalVisible && (
        <Blackout onClick={closeModal}>
          <StyledModal 
            title={t('Meme Season on ZSwap')} 
            headerBackground="gradients.cardHeader" 
            style={{ maxWidth: '360px' }}>
            <Text mb="30px">Memes have taken over <AvaxIcon style={{ margin: '4px 0 -4px 4px'}}/> AVAX. Trade our own memecoin, SPONK now!</Text> 
            <Text mb="30px">Are you interested in launching a memecoin? Contact us to learn about incentives for your memecoin launch!</Text>
            <Button variant="secondary" onClick={closeModal} mb="20px">
              {t('Close')}
            </Button>
          </StyledModal>
        </Blackout>
      )}
    </>
  )
}

export default Home
