import { useMemo } from 'react'
import styled from 'styled-components'
import { BoxProps, Box, Flex, FlexProps } from '@pancakeswap/uikit'
import Container from 'components/Layout/Container'
import CurvedDivider from './CurvedDivider'
import { ClipFill, DividerFill } from './types'
import CategoryBanner from './CategoryBanner'
import CategoryStats from './CategoryStats'
// import CakeDataRow from './components/CakeDataRow'

interface PageSectionProps extends BackgroundColorProps {
  svgFill?: string
  dividerComponent?: React.ReactNode
  hasCurvedDivider?: boolean
  hasCategoryBanner?: boolean
  dividerPosition?: 'top' | 'bottom'
  concaveDivider?: boolean
  containerProps?: BoxProps
  innerProps?: BoxProps
  clipFill?: ClipFill
  dividerFill?: DividerFill
  pageWidth?: 'standard' | 'wide' | 'ultrawide'
}

interface BackgroundColorProps extends FlexProps {
  index: number
  background?: string
  padding?: string
  hasCategoryBanner?: boolean
}

const BackgroundColor = styled(Flex)<BackgroundColorProps>`
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: ${({ index }) => index - 1};
  background: ${({ background, theme }) => background || theme.colors.background};
  background-position: center center;
  background-size: cover;
  padding: ${({ padding }) => padding};
  margin-top: ${({ hasCategoryBanner }) => hasCategoryBanner ? '' : '0px' };
`

const ChildrenWrapper = styled(Container)`
  min-height: auto;
  padding-top: 32px;
  padding-bottom: 80px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 32px;
    padding-bottom: 80px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 80px;
  }
`

const ThirdGradient = styled.div`
  position: relative;
  background: linear-gradient(-270deg, rgb(247, 20, 255, 0.28), rgb(8, 200, 255, 0.38));
  z-index: 2;
`

const PageSection: React.FC<PageSectionProps> = ({
  children,
  background,
  svgFill,
  index = 1,
  dividerComponent,
  dividerPosition = 'bottom',
  hasCategoryBanner = false,
  concaveDivider = false,
  clipFill,
  dividerFill,
  containerProps,
  innerProps,
  pageWidth,
  ...props
}) => {
  const padding = useMemo(() => {
    // Category Banner
    if (hasCategoryBanner) {
      return '68px 0'
    }

    return '68px 0'
  }, [dividerPosition, hasCategoryBanner])
  
  const width =  pageWidth === 'ultrawide' ? '2280px' : '1680px' 
  const maximumWidth = '100%' 

  return (
    <Box {...containerProps}>
      {hasCategoryBanner && (
        <ThirdGradient>
          <CategoryBanner /> {/* Main 4 categories: Swap, Farm etc */}
        </ThirdGradient>
      )}
      <BackgroundColor index={index} hasCategoryBanner={hasCategoryBanner} background={background} {...props}>
        <ChildrenWrapper {...innerProps} style={{ width: `${width}`, maxWidth: `${maximumWidth}` }}>{children}</ChildrenWrapper>
      </BackgroundColor>
      
      {/* 
      {hasCategoryBanner && (
        <ThirdGradient>
          <CategoryStats /> 
        </ThirdGradient> 
      )}
      */}
    </Box>
  )
}

export default PageSection
