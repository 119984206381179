import { useState } from 'react'
import Link from 'next/link'
import { Button, Flex, Heading, Modal } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import { Box } from '@material-ui/core'

import useTheme from 'hooks/useTheme'
import styled, { keyframes } from 'styled-components'
import CompositeImage, { CompositeImageProps } from './CompositeImage'
import { SlideSvgDark, SlideSvgLight } from './SlideSvg'

// Left here for reference - these images have been converted to base 64
import home1 from '../../../../public/images/home/hero/home-1.png'
import home2 from '../../../../public/images/home/hero/home-2.png'



const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`
const flyingAnim2 = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-3px, -2px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const HeroDiv = styled.div`
  position: relative;
  z-index: 2;
`

const HeroWrapper = styled.div`
  top: 20px;
  max-width: 600px;
  position: absolute;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  > span {
    overflow: visible !important;
  }
`

const Hero2Wrapper = styled.div`
  max-width: 600px;
  animation: ${flyingAnim2} 3.5s ease-in-out infinite;
  animation-delay: 600ms;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const FigureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
`

const DummyImg = styled.img<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  visibility: hidden;
`

const MainHeading = styled(Heading)`
  text-transform: uppercase;
  letter-spacing: -2px;
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  margin: 15px 0;
  max-width: 340px;
  background: linear-gradient(270deg,rgba(8,200,255,1) 0%,rgba(240,50,245,1) 100%);
  // background: linear-gradient(270deg, rgba(240, 50, 245, 1) 0%, rgb(215 47 48) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media all and (max-width: 449px){
    font-size: 34px;
    line-height: 34px;
  }
`
const MainBlock = styled(Box)`
  display: block !important;
  position: relative;
  padding: 2px 0 0;
  margin: 0 0 10px;
  text-align: center;
  width: 155px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-transform: uppercase;

  border-radius: 8px;
  border: 2px solid #08C8FF;
  color: #08C8FF;
  background: transparent;
  box-shadow: 0 0 8px rgba(0,255,255,0.2), 0 0 8px rgba(0,255,255,0.1) inset;
  cursor: pointer;
  &:hover{
    color: #181A2C;
    background: #08C8FF;
  }
`;
const ContentBox = styled(Box)`
  max-width: 520px;
  @media all and (min-width: 1200px){
    margin-left: 100px;
  }
`

interface HeroProps {
  onOpenModal: () => void;
}

const Hero: React.FC<HeroProps> = ({ onOpenModal }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { theme } = useTheme()

  return (
    <>
      <HeroDiv>
        <Flex
          position="relative"
          flexDirection={['column-reverse', null, null, 'row']}
          alignItems={['flex-start', null, null, 'center']}
          justifyContent="center"
          mt={[account ? '0' : '0', null, 0]}
          id="homepage-hero"
        >
          <Flex flex="1" flexDirection="column">
            <ContentBox>
              <MainHeading scale="xxl" color="white" mb="24px">
                MEME SZN
              </MainHeading>
              {/* <Link href="/info"> */}
              <Box onClick={onOpenModal} style={{ display: 'inline-block'}}>
                <MainBlock >View Info</MainBlock>
              </Box>
              {/* </Link> */}
              <Heading scale="md" color="white" mt="10px" mb="16px" style={{ fontSize: '14px', fontWeight: '600' }}>
                {t('Meme season is coming to ZSwap! ')}
              </Heading>
              
              <Flex>
                {!account && <ConnectWalletButton mr="8px" style={{ width: '155px'}} />}
                {account && <>
                  <Link href="/swap">
                    <Button variant="primary" mr="8px" style={{ width: '135px'}}>
                      {t('Swap')}
                    </Button>
                  </Link>
                  {/* 
                  <Link href="/nfts">
                    <Button variant="secondary" mr="8px" style={{ width: '135px'}}>
                      {t('Trade NFTs')}
                    </Button>
                  </Link>
                  */}
                </> }
                {/* {account && 
                <NextLinkFromReactRouter to="/swap">
                    <Button variant="secondary" >{t('NFT Marketplace')}</Button>
                </NextLinkFromReactRouter>} */}
              </Flex>
            </ContentBox>
          </Flex>
          <Flex
            height={['190px', null, null, '555px']}
            width={['320px', null, null, '100%']}
            flex={[null, null, null, '1.44']}
            mb={['24px', null, null, '0']}
            position="relative"
          >
            <HeroWrapper>
              <img
                src="/images/home/hero/car-1.png"
                alt=""
                width="1240"
                height="1000"
              />
            </HeroWrapper>
            <Hero2Wrapper>
              <img
                src="/images/home/hero/car-2.png"
                alt=""
                width="1240"
                height="1000"
                style={{ filter: 'blur(0.5px)', rotate: '1deg'}}
              />
            </Hero2Wrapper>

            {/* Dark check:
            {theme.isDark ? <img src="/images/home/hero/home-1.png" placeholder="blur" alt="Hover Car" /> 
              : <img src="/images/home/hero/home-2-light.png" placeholder="blur" alt="Hover Car" /> }
            */}
            {/* <FigureWrapper>
              {theme.isDark ? <img src="/images/home/hero/home-3-beta.png" placeholder="blur" alt="Building..." /> 
              : <img src="/images/home/hero/home-3-beta.png" placeholder="blur" alt="Building..." /> }
            </FigureWrapper> */}
          </Flex>
        </Flex>
      </HeroDiv>
    </>
  )
}

export default Hero
