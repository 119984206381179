import { Button, Flex, Heading, Text, Image } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'
import useTheme from 'hooks/useTheme'
import styled, { keyframes } from 'styled-components'
import CompositeImage, { CompositeImageProps } from './CompositeImage'
import { SlideSvgDark, SlideSvgLight } from './SlideSvg'

const building = '/images/home/notifications/meme-szn.png'

const convertVhToPx = (vh = 100) => {
  if (typeof window !== 'undefined') {
    const oneVhInPx = window.innerHeight / 100
    return oneVhInPx * vh
  }
  return 0
}

const notificationAnim = () => keyframes`
  from {
    right: -480px;
    opacity: 1;
  }
  1% {
    right: -480px;
  }
  1.8% {
    right: -40px;
  }
  90% {
    right: -40px;
    opacity: 1;
  }
  92% {
    right: -40px;
    opacity: 0;
  }
  to {
    right: -480px;
    opacity: 0;
  }
`

const NotificationWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 120px;
  max-width: 420px;
  right: 0px;
  z-index: 12;
  overflow: hidden;
  top: 5px;

  ${({ theme }) => theme.mediaQueries.md} {
    top: Calc(100vh - 200px);
    ${convertVhToPx() >= 750 ? 'top: 520px;' : ''}
  }

  @media (min-width: 968px) {
    top: Calc(100vh - 200px);
    ${convertVhToPx() >= 750 ? 'top: 555px;' : ''}
  }

  @media (min-width: 1200px) {
    top: Calc(100vh - 200px);
    ${convertVhToPx() >= 850 ? 'top: 680px;' : ''}
  }

  ${({ theme }) => theme.mediaQueries.xxxl} {
    top: Calc(100vh - 200px);
    ${convertVhToPx() >= 940 ? 'top: 760px;' : ''}
  }
`

const Notification = styled.a`
  display: block;
  position: relative;
  display: flex;
  align-items: center;
  width: Calc(100% - 80px);
  right: -300px;
  height: 70px;
  top: 10px;
  animation: ${notificationAnim} 120s ease-in-out infinite;
  border-radius: 12px;
  font-weight: 600;
  background: ${({ theme }) => (theme.isDark ? 'rgba(3,5,11,0.5)' : 'rgba(255,255,255,0.9)')};
  box-shadow: 0px 1px 4px rgb(10 10 10), rgb(216 0 255 / 10%) 0px 4px 8px;
  padding: 10px 15px 10px;

  ${({ theme }) => theme.mediaQueries.md} {
    height: 90px;
  }
`

const NotificationIcon = styled.img`
  display: block;
  width: 54px;
  height: 54px;
  margin-right: 15px;
  @media all and (max-width: 851px) {
    width: 36px;
    height: 32px;
    margin-right: 10px;
  }
`

const NotificationText = styled(Text)`
  display: block;
  font-size: 14px;
  padding-right: 10px;
  @media all and (max-width: 851px) {
    font-size: 12px;
  }
`

const NotificationLink = styled(Text)`
  display: inline-block;
  text-transform: uppercase;
  bottom: 10px;
  right: 10px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 8px;
  line-height: 1;
  border: 2px solid #d800ff;
  border-radius: 12px;
`

const NewsNotification = () => {
  return (
    <NotificationWrapper>
      <Notification href="/swap?outputCurrency=0xa82c685fBB60c6912cfD279C3A0BeA1b0d5850e1" rel="noreferrer">
        <NotificationIcon src={building} />
        <NotificationText>
          Meme szn is starting on ZSwap... Trade SPONK now!
        </NotificationText>
      </Notification>
    </NotificationWrapper>
  )
}

export default NewsNotification